
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,500;1,400&display=swap');

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: cambria math;
  /* font-family: 'Poppins', sans-serif; */

}

.App{
  position: relative;
  /* background-color:#fff;  */
}

body{
    background-color: #eee; 
}

:root{
  --backgroundColor : #ff8c1a;
  --whiteColor : #fff;
}

html,body{
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
}

.swiper {
  width: 80%;
}

.item-slide{
    display: flex;
    justify-content: center;
    padding:2%;
    /* align-items: center; */
  }

.cat-slide{
  display:flex;
  flex-direction:column;
  align-items:center;
}




 nav{
  background-color: var(--backgroundColor);
  box-shadow: 1px 1px 20px 1px #000;
  /* position: relative; */
  position:fixed;
  width:100%;
  z-index: 10;
  top:0;
}

.nav-bar{
  display: flex;
  position: relative;
  margin:auto 5%;
  padding:5px;
  justify-content: space-between;

}

nav .hLogo{
  font-size:2.5rem;
  font-weight: bold;
  color: var(--whiteColor);
  margin:0 7%;
  text-shadow: 1px 1px 2px var(--whiteColor);
  letter-spacing: 1px;
  
}


nav .menu{
  display: flex;
  align-items: center;
  padding:5px;
}

 nav .menu .navbar-menu-name{
  margin-right:10px;
  text-decoration: none;
  color:var(--whiteColor);
  /* font-family:cambria math; */
  font-size:26px;
  font-weight: 400;
}

/*------->  Redesign this */
.icon-style{
   margin-left:22px;
   margin-right: 5px;
   font-size:24px;
   color:var(--whiteColor);
}

/*------->  Redesign this */

#search-icon{
  display: none;
}

.cart-count{
   width:20px;
   font-size:12px;
   top:7px;
   right:60px;
   color:var(--whiteColor);
   background-color: red;
   position: absolute;
   border-radius: 50%;
   text-align: center;
   padding:2px 1px;
}


.search-form{
    border: 1px solid var(--whiteColor);
    border-radius: 10px;
    background-color: var(--whiteColor);
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-form input{
  padding:5px;
  color:brown;
  /* font-family: cambria math; */
  font-size:22px;
  margin:auto 7px;
  outline: none;
  border: none;
}

.search-form label{
  color:var(--backgroundColor);
  font-size:24px;
  font-weight: bold;
  text-align: center;
  margin:auto 5px;
}

.search-form label:hover{
   cursor: pointer;
}


.header-carosol{
  width:100%;
  height:94vh;
  position: relative;
  background: #000;
  z-index: 1;
  margin-top: 3%;
}

.carosol-img{
  width:100%;
  height:100%;
  opacity: 0.6;
}

.img-indicator{
  position: absolute;
  height:9px;
  width:9px;
  bottom:7%;
  border-radius: 50%;
  background-color: var(--whiteColor);
}

.img-indicator-test{
  position: absolute;
  height:9px;
  width:9px;
  bottom:7%;
  border-radius: 50%;
  background-color: var(--whiteColor);
  left:47%;
}

#ind-one{
  left:47%;
}

#ind-two{
  left:49%;
}

#ind-three{
  left:51%;
}

.left-arrow,.right-arrow{
  position: absolute;
  font-size:68px;
  top:50%;
  transform: translateY(-50%);
  color:var(--whiteColor);
  display: none;
}
.left-arrow{
  left:3%;
}
.right-arrow{
  right:3%;
}

.left-arrow:hover,.right-arrow:hover{
  cursor:pointer;
}

.ruler{
    border:none;
    height:30px;
    border-bottom:5px dotted black;
    background-color:transparent;
    width:5%;
    margin:2% auto;
}


/* .custom-caption{
  position: absolute;
  top:30%;
  left:50%;
  transform: translateX(-50%);
  text-align:center;
  padding:10px;
  z-index: 3;
} */

.custom-caption{
 position: absolute;
  padding:10px;
  z-index: 3;
  left:50%;
  top:50%;
  width:90%;
  text-align:center;
  transform: translate(-50%,-50%);
}

.custom-caption h1{
  font-size:6rem;
  color:var(--whiteColor);
  /* font-family: cambria math; */
  letter-spacing: 1px;
  font-weight: bold;
  margin: 3% auto;
  text-shadow: 1px 1px 5px var(--whiteColor);
}

.custom-caption h3{
  font-size:4.5rem;
  color:var(--whiteColor);
  /* font-family: cambria math; */
  margin: 5% auto;
  font-weight: bold;
  letter-spacing: 1px;

}

.custom-caption .caption-style{
  color:lightgreen;
  text-shadow: 1px 1px 3px yellow;
}

/*Category Section*/

#category-section{
  width: 100%;
  background-color: var(--backgroundColor);
}

.cat-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding:8px auto;
  width:auto;  
}

.category-continer {
   margin: 1% 4%;
   text-align: center;
}

.cat-swiper-wrapper{
  margin-bottom:1.5%;
}

.category-continer img{
   width:50px;
   color: var(--whiteColor);
}

.category-continer img:hover{
   cursor: pointer;
   scale:1.2;
}

.category-continer :hover{
  cursor: pointer;  
}

.category-txt{
  font-size:22px;
  color: var(--whiteColor);
}

#item-section {
  margin:3% 5%;
}

#custom-item-section{
    margin:3%  auto;
    padding:0% 4%;
    max-width:70%;
}

.custom-card-style{
  margin:3% auto;
}

.custom-item-row{
  margin:3% auto;
}

.items-container{
  display: flex;
  justify-content: center;
  margin: 3% auto ;
  position: relative;
}

.card-style{   
   padding:2%;
   max-width:400px;  
   margin:auto;   
   /* text-align: center; */

}

.item-heading{
  color:var(--backgroundColor);
  font-size:3.2rem;
  font-weight: bold;
  text-align: center;
}

.section-heading{
  color:var(--backgroundColor);
  font-size:3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3%;
}

.cd-title{
  color:var(--backgroundColor);
  /* font-family:cambria math; */
  font-size: 32px;
  text-shadow: 1px 1px 1px orange;
}

.cd-text{
  /* font-family:cambria math; */
  font-size: 18px;
  text-indent: 25px;
  text-align: justify;

}

.btn-cart{
  background-color: var(--backgroundColor);
  color:var(--whiteColor);
  padding: 6px 10px;
  border-radius:10px;
  text-decoration: none;
  font-size:18px;
  /* max-width:200px;   */
  border: 1px solid var(--backgroundColor);
  /* font-family: cambria math; */

}

.btn-cart-edit{
  color:var(--whiteColor);
  padding: 6px 10px;
  border-radius:10px;
  text-decoration: none;
  font-size:18px;
}

.btn-cart-edit:hover{
  color:var(--whiteColor);  
}


.btn-cart-icon{
  margin-right:3px;
  font-size:21px;
  color:var(--whiteColor);

}





.btn-cart:hover{
  color:var(--whiteColor);
}

/* .footer-section{
  background-color: var(--backgroundColor);
  width: 100%;
  padding:5px;
  text-align: center;
} */

.footer-section{
  background-color: var(--backgroundColor);
  width: 100%;
  padding:5px;
  text-align: center;
  position: fixed;
  bottom: 0;
  z-index: 100;
}


.footer-txt{
  /* font-family: cambria math; */
  font-size: 20px;
  margin: 2px auto;
  letter-spacing: 2px;
  color: var(--whiteColor);
}

.footer-link{
  /* font-family: cambria math; */
  font-size:22px;
  letter-spacing: 1px;
}

.footer-link{
  /* font-family: cambria math; */
  font-size:22px;
  letter-spacing: 1px;
  color: var(--whiteColor);
  text-decoration: none;
  text-shadow: 1px 1px 2px grey;
}

.footer-link:hover{
  cursor: pointer;
  color: var(--whiteColor);
  text-shadow: 1px 1px 2px yellow;
}

.item-next-arrow,.swiper-button-next,.swiper-button-prev{
  color: grey;
  opacity: 0.5;
  font-size: 8rem;
}

.item-next-arrow:hover{
  cursor: pointer;
  opacity: 1;
}

.contact-section{
  margin: 5% auto 2%;
}

#experience-section,#locate-us{
  margin: 3% auto 5%;
  padding: 3px;
  max-width: 1300px;
}


.exp-header{
  font-size:3.5rem;
  font-weight: bold;
  color: var(--backgroundColor);
  text-shadow: 1px 1px 3px var(--backgroundColor);
  margin-bottom:3%;
}

.exp-txt{
  font-size:1.2rem;
  text-indent: 2rem;
  color:brown;
  text-align: justify;
  margin-top:auto;
}

.locate-header{
  font-size:2.5rem;
  font-weight: bold;
  color: var(--backgroundColor);
  margin-bottom:2%;
}

.locate-txt{
  font-size:1.2rem;
  color:brown;
  text-align: justify;
}


#contact-us{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin: 5% auto 2%;
}


#experience-section img,#locate-us .map-img{
  width:70%;
  border-radius: 10px;
  margin:3% 22%;
  box-shadow: 1px 1px 10px 1px grey;
}

#locate-us .map-img{
  margin:0% 22%;
}

#locate-us .shop-address{
  margin-top:2%;
  margin-left:auto;
}

#experience-section .exp-data{
  margin-top: auto;
  padding:auto;
}

.mail-enclosing{
  display: flex;
  align-items: center;
  margin: -2% auto 0%;
}

.mail-icon{
  color:brown;
  font-size:1.8rem;
  text-align: center;
  margin-right: 5px;
  margin-bottom:2%;
}

.mail-link{
  text-decoration: none;
  color:brown;
  font-size:2rem;
  letter-spacing: 1.5px;
  margin-bottom:2%;
}

.mail-link:hover{
  color:brown;
}


/*  */

.mySwiper{
  padding:0% 4%;



}
.swiper-content{
  width: 100%;
  margin: 0% 1%;
}

/**/


#overlay-section{
  position: absolute;
  height:100%;
  top:0%;
  left:0%;
  right:0%;
  width:100%;
  z-index: 999;
  background-color: rgba(0,0,0,0.8);  
}

.overlay-container{
  position: absolute;
  border:1px solid grey;  
  width:50%;
  height:300px;
  top:50%;
  left:50%;
  border:3px solid #fff;
  border-radius: 8px;
  box-shadow: 1px 1px 10px 3px #fff;
  transform: translate(-50%,-50%);
}

.HomeContainer{
  position: relative;  
  background-color: #fff;
}


/**/


#user-login-section,#alert-section,#product-info-section{
  position: absolute;  
  top:-1.5%;
  left:0%;
  right: 0%;
  bottom:0%;
  z-index: 100;
  width:100%;
  height: 102.5%;
  /* height:100vh; */
  background-color: rgba(0, 0, 0, 0.8);
  /* display:none; */

}

/* #user-login-section{
    display:block;
} */

/* #alert-section{
  display: block;
} */

.alert-container{
  border:4px solid #F5761A;
  background-color: #fff;
  position: absolute;
  left:50%;
  top:8%;
  width: 500px;
  border-radius: 15px;
  text-align:center;
  /* padding:10px auto; */
  transform: translate(-50%,-50%);
}

.alert-heading{
  background-color: #F5761A;
  color: #fff;
  padding: 5px 3px ;
  margin-bottom:5%;
}

.alert-txt{
  color:brown;
  font-size:1.5rem;
  margin:2% auto;
}

.alert-btn{
  width:20%;
  border-radius:10px;
  background-color:#F5761A;
  border:1px solid var(--backgroundColor);
  color:#fff;
  font-size:1.3rem;
  margin: 3% auto;
  padding:3px 2px;
  transition: color 0.3s ease;
}

.alert-btn:hover{
    background-color:var(--backgroundColor);
}




.form-close-icon{
  color: var(--backgroundColor);
  font-size:4rem;
  /* position: absolute; */
  position: fixed;
  top:10px;
  right: 2%;
  transition: color 0.3s ease;
}

.form-close-icon:hover{
   cursor: pointer;
   color:#F5761A;
}


.user-login-form{
   width:50%;
   border:4px solid #F5761A;
   background-color: #fff;  
   position: fixed;
   left:50%;   
   top:50%;
   max-width: 700px;
   border-radius: 15px;
   transform: translate(-50%,-50%);
}

#home-container{

}

.user-login-form{
  padding:5px 10px;
}

.form-logo{
  width:18%;
  border-radius: 50%;
  border: 4px solid #F5761A;
  background-color: #fff;
  margin: -10% auto -2%;
  padding:10px;
  text-align: center;
}


.form-logo img{
  width:90%;
}


.user-login-heading{
   font-size: 2.3rem;
   color:#F5761A;
   text-align: center;
   margin:3% auto;
}

#register-form,#login-form {
  margin: 5% auto;
  padding: 2% auto;
  text-align: center;
}

#register-form{
  display: none;
}


.form-icon-style{
   margin-right: 12px;
   font-size:24px;
   color:  #F5761A;;
}

#register-form input,#login-form input{
  padding: 7px 5px;
  margin:8px auto;
  outline: none;
  border:1px solid #F5761A;
  border-radius: 5px;
  width:50%;
  color: brown;
  font-size:18px;
}

#register-form input[type="submit"],#login-form input[type="submit"]{
  width:25%;
  border-radius:10px;
  background-color:#F5761A;
  border:1px solid var(--backgroundColor);
  color:#fff;
  font-size:18px;
  padding:5px auto;
  margin: 3% auto 1%;
  transition: color 0.3s ease;
}

#register-form input[type="submit"]:hover,#login-form input[type="submit"]:hover{
  background-color: var(--backgroundColor);
}

.form-btn{
  width:25%;
  border-radius:10px;
  background-color:#F5761A;
  border:1px solid var(--backgroundColor);
  color:#fff;
  font-size:18px;
  padding:5px;
  margin: 3% auto;
  transition: color 0.3s ease;
}

.form-btn:hover{
    background-color: var(--backgroundColor);
}


.form-signup-link{
  text-decoration: none;
  font-size:1.7rem;
  color:#F5761A;
  text-shadow: 1px 1px 3px #F5761A;
  transition: color 0.3s ease;
}

.form-signup-link:hover{
  color: var(--backgroundColor);
}


.swiper_container{
       
}

.swiper_product_container{
   
  margin:1% auto;  
  /* text-align: center; */
  
}

/* Cart Page  */

#cart-page{  
   /* border:1px solid #fff; */
   position: relative;
   /* background-color:#eee;     */
   
   /* height:96vh;   */
   /* height:auto;
  */
}

.container-style{
  width:80%;
  max-width:1300px;
  padding:10px 15px;
  margin:  7% auto;
  /* background-color: #fff;   */
}

.cart-items-container{
  /* background-color: #fff; */
  border-radius:5px;
  padding:5px;
  margin-bottom:5%;
}

.cart-items{
  /* background-color: #fff; */
  background-color: #fff;
  /* border: 3px solid #F5761A; */
  display: flex;
  flex-direction:  row;
  align-items: center;
  padding:15px;
  margin: 2% auto;
  border-radius: 10px;  
  /* box-shadow: 1px 2px 15px 2px rgba(0,0,0,0.5); */

}

.cart-img{
  border:1px solid rgba(0,0,0,0.3);
  margin-right:5%;
  border-radius:10px;
  text-align: center;
  width:35%;
}

.cart-img img{
  width:60%;
}

.cart-item-info{
  margin-left:5%;
}

.cart-item-info .itemName{
   font-size:1.8rem;
   color:orangered;
}

.cart-item-info .itemQty{
  font-size:1.2rem;
  color:chocolate;
}

.cart-item-info .itemPrice{
  font-size:1.3rem;
  color:chocolate;
}

/* .cart-price-container{
   background-color: #fff;
   margin:auto;
   padding:1%;
}

.cart-price-container table {
  width:100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-bottom:3%;

}

.cart-price-container table tr h2{
   font-size:1.7rem;
}

.cart-price-container table tr td{
   font-size:1.3rem;

} */

#cart-price-total{
  background-color: #fff;
  margin-top:4%;
  padding: 20px;  
  border-radius:5px;
  /* box-shadow: 1px 2px 10px 2px rgba(0,0,0,0.5); */
}

.cart-price-heading{
  color: #F5761A;
  margin:5px auto 20px;
  text-shadow: 1px 1px 1px #ff8c1a;
  
}

.cart-summary-items{
  display: flex;
  justify-content: space-between;
  margin: 10px 8px;

}

.totalPrice{
  border-top: 1px solid rgba(0,0,0,0.2);
  margin-top:-8px;
  padding-top:10px;
}

.cart-summary-btn{
   padding: 6px 8px;
   font-size:1.5rem;
   color:#fff;
   background-color: #ff8c1a;
   border:none;
   border-radius: 5px;
   width:100%;  
   
}



.cart-item-info .cart-button{
  /* width:50%; */
  padding:3px 8px;  
  background-color:rgba(255,0,0,0.8);
  background-color: red;
  color:#fff;
  border:1px solid red;
  border-radius:7px;
  font-size:1.1rem; 

}

.empty-cart-txt{
  color:red;
  font-size:2.3rem;
  text-shadow: 1px 1px 3px orange;
  text-align: center; 
}


/*Product info Section*/

.product-info-container{
  width:70%;
  border:4px solid #F5761A;
  background-color: #fff;
  position: fixed;
  left:50%;
  top:50%;
  /* max-width: 1500px; */
  height:70vh;
  border-radius: 20px;
  transform: translate(-50%,-50%);

}

/* Product Info Section */

.product-info-items{
  display: flex;
  flex-direction:  row;
  align-items: center; 
  margin: 3% auto;
  padding: 3%;
  width:85%;
  /* border:1px solid rgba(0, 0, 0, .2);
  border-radius:25px;  
  box-shadow: 1px 1px 10px 5px #ff8c1a; */
 
}

.Shop-title{
  font-size:5rem;
  text-align: center;
  color: #ff8c1a;
  margin: 2% auto;
  text-shadow: 1px 3px 5px #F5761A;
}

.product-info-img{
  /* border:1px solid grey; */
  margin-right:7%;
  border-radius:20px;
  text-align: center;
  width:50%;  
}

.product-info-img img{
  width:60%;
}


.product-info{
  margin-left:3%;
  margin-top: auto;
}

.product-info .itemName{
   font-size:3.4rem;
   color:#F5761A;
   font-family: Cambria math;
   margin-bottom: 10px;
}

.product-info .itemQty{
  color:#ff8c1a;
}

.product-info .itemPrice{
   font-size:1.5rem;
   color:red;
}

.product-info .qtySection button{
   width:35px;
   font-size:1.5rem;
   background-color: #ff8c1a;
   color:#fff;
   border:none;
   border-radius: 50%;
}

.product-info .qtyInput{
    width:20%;
    padding:5px;
    color:#F5761A;
    margin: 10px 5px;    
    text-align: center;     
    border:1px solid #F5761A;
    border-radius:5px;
    font-size:1.3rem; 
    outline: none;  
}

.product-info .itemPrice{
  font-size: 2.3rem;
  color:#F5761A;
  margin: 12px auto;

}

.product-info-cart-btn{
  width:60%;
  color:#fff;
  border:none;
  border-radius:10px;
  padding:8px;
  font-size:1.3rem;
  margin: 15px auto;
  background-color: #ff8c1a;
}

.product-info-cart-btn i {
  font-size: 1.5rem;
}


