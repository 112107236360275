/* Media Queries*/


@media screen and (max-width : 480px){

    .swiper {
      width: 100%;
    }
  
  
    .nav-bar{
      margin:0 2%;
      padding:3px 2px;
    }
  
    nav .header-logo{
      font-size:2.3rem;
      margin:1% 3%;
    }
  
    nav .menu{
      padding:3px;
    }
  
    nav .menu .navbar-menu-name{
      display: none;
    }
  
    #search-icon{
      display: block;
    }
  
    #search-icon:hover{
      cursor: pointer;
    }
  
    .icon-style{
         margin-left:20px;
         margin-right: 2px;
         font-size:28px;
      }
  
    .search-form{
          border-radius: 10px;
          display: none;
          position: absolute;
          top:120%;
          right: -100%;
      }
      .search-form-active{
            border-radius: 10px;
            display: block;            
            position: absolute;
            top:105%;   
            right:0%; 
            background-color: #fff;  
            padding:3px;   
            width: 100%;     
            /* width:400px; */
          }

          .search-form-active input{
            padding: 8px 8px;           
            width:85%;
            color:brown;
            font-size:1.5rem;
            border:none;
            outline: none;
          }          
          
          .search-form-active i{
            padding: 8px 10px;
            margin: auto 4%; 
            font-size:1.5rem;                   
          }

          .search-form-active i:hover{
            cursor: pointer;                 
          }
  
        .cart-count{
           width:20px;
           font-size:12px;
           top:5px;
           right:0px;
        }
  
        .search-form input{
          padding: 8px 5px;
          margin:auto 7px;
        }
  
        .header-carosol{
          width:100%;
          height:40vh;
          margin-top: 8%;
        }
  
        .carosol-img{
          width:100%;
          height:100%;
          opacity: 0.6;
          object-fit: fill;
        }
  
        .custom-caption{
            top:25%;
            left:45%;
            transform: translateX(-45%);
            padding:3px;
            width: 90%;
          }
  
          .custom-caption h1{
            font-size:2.2rem;
            margin: 3% auto;
          }
  
          .custom-caption h3{
            font-size:1.8rem;
            margin: 7% auto;
            letter-spacing: 2px;
  
          }
  
          .img-indicator{
            position: absolute;
            height:9px;
            width:9px;
            bottom:7%;
            border-radius: 50%;
            background-color: var(--whiteColor);
          }
  
          .img-indicator{
            height:10px;
            width:10px;
            bottom:7%;
          }
  
          #ind-one{
            left:45%;
          }
  
          #ind-two{
            left:50%;
          }
  
          #ind-three{
            left:55%;
          }
  
          .left-arrow,.right-arrow{
            font-size:48px;
          }
          .left-arrow{
            left:1%;
          }
          .right-arrow{
            right:1%;
          }
  
      /**/
      .cat-container{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding:8px auto;
        width:100%;      
      
      }
  
       .category-continer{
         padding:1px auto;
         margin:auto 1%;
       }
  
       .cat-swiper-wrapper{
         margin-bottom:3%;
       }
  /**/
       #item-section{
         margin:5%;
       }
  
       .items-container{
         display: grid;
         grid-template-columns: repeat(auto);
         position: relative;
         margin:10% auto;
       }
  
       .card-style{
          margin:2% auto 5%;
          padding:15px 8px;
          width: 330px;
       }
  
       .item-heading{
         font-size:2.2rem;
       }
  
       .section-heading{
         font-size:2.2rem;
         margin-bottom: 5%;
       }
  
       .cd-title{
         font-size: 1.5rem;
       }
  
       .cd-text{
         font-size: 1.3rem;
       }
  
       .btn-cart{
         padding:10px;
         font-size:1rem;
       }
  
       .footer-section{
         padding:3px;
       }
  
       .footer-txt{
         font-size: 1.1rem;
         letter-spacing: 1px;
       }
  
       .footer-link{
         font-size:1.2rem;
         letter-spacing: 1.2px;
       }
  
       .item-next-arrow{
         display: none;
       }
  
       #experience-section,#locate-us{
         margin: 3% auto 5%;
         padding: 2px;
       }
  
       .exp-header{
         font-size:2rem;
         margin-bottom:3%;
         text-align: center;
       }
  
       .exp-txt{
         font-size:1.2rem;
         text-indent: 2rem;
         margin-top:5%;
       }
  
       .locate-header{
         font-size:1.7rem;
         margin-bottom:2%;
       }
  
       .locate-txt{
         font-size:1.3rem;
       }
  
       #contact-us{
          margin: 5% auto 10%;         
       }
  
       #experience-section img,#locate-us .map-img{
         width:85%;
         margin:3% 7%;
         box-shadow: 1px 1px 10px 1px grey;
       }
  
       #locate-us .shop-address{
         margin-top:5%;
         margin-left:7%;
       }
  
       #experience-section .exp-data{
         margin-top: 5%;
         padding:2px 20px;
       }
  
  
       .mail-icon{
         font-size:1rem;
         margin-right: 3px;
         margin-bottom:5%;
       }
  
       .mail-link{
         font-size:1.1rem;
         letter-spacing: 0.4px;
         margin-bottom:5%;
       }
  
  
    
  
  #user-login-section,#alert-section{
    position: absolute;  
    top:-1.5%;
    left:0%;
    right: 0%;
    bottom:0%;
    z-index: 100;
    width:100%;
    height: 102.5%;
    background-color: rgba(0, 0, 0, 0.8);
    /* display:none; */
  
  }
  
  /* #user-login-section{
      display:block;
  } */
  
  /* #alert-section{
    display: block;
  } */
  
  .alert-container{
    border:4px solid #F5761A;
    background-color: #fff;
    position: absolute;
    left:50%;
    top:2%;
    width: 500px;
    border-radius: 15px;
    text-align:center;
    /* padding:10px auto; */
    transform: translate(-50%,-50%);
  }
  
  .alert-heading{
    background-color: #F5761A;
    color: #fff;
    padding: 5px 3px ;
    margin-bottom:5%;
  }
  
  .alert-txt{
    color:brown;
    font-size:1.5rem;
    margin:2% auto;
  }
  
  .alert-btn{
    width:20%;
    border-radius:10px;
    background-color:#F5761A;
    border:1px solid var(--backgroundColor);
    color:#fff;
    font-size:1.3rem;
    margin: 3% auto;
    padding:3px 2px;
    transition: color 0.3s ease;
  }
  
  .alert-btn:hover{
      background-color:var(--backgroundColor);
  }
  
  
  
  
  .form-close-icon{
    color: var(--backgroundColor);
    font-size:2.5rem;
    position: fixed;
    top:20px;
    right: 5%;
    transition: color 0.3s ease;
  }
  
  .form-close-icon:hover{
     cursor: pointer;
     color:#F5761A;
  }
  
  
  .user-login-form{
     width:85%;     
  }
  
  #home-container{
  
  }
  
  .user-login-form{
    padding:5px 3px;
  }
  
  .form-logo{
    width:30%;
    border-radius: 50%;
    border: 4px solid #F5761A;
    background-color: #fff;
    margin: -15% auto -2%;
    padding:10px;
    text-align: center;
  }
  
  
  .form-logo img{
    width:90%;
  }
  
  
  .user-login-heading{
     font-size: 1.8rem;
     color:#F5761A;
     text-align: center;
     margin:5% auto ;
  }
  
  #register-form,#login-form {
    margin: 5% auto;
    padding: 2% auto;
    text-align: center;
  }
  
  #register-form{
    display: none;
  }
  
  
  .form-icon-style{
     margin-right: 8px;
     font-size:26px;
     color:  #F5761A;;
  }
  
  #register-form input,#login-form input{
    padding: 7px 5px;
    margin:10px auto;
    outline: none;
    border:1px solid #F5761A;
    border-radius: 5px;
    width:80%;
    color: brown;
    font-size:18px;
  }
  
  #register-form input[type="submit"],#login-form input[type="submit"]{
    width:25%;
    border-radius:10px;
    background-color:#F5761A;
    border:1px solid var(--backgroundColor);
    color:#fff;
    font-size:18px;
    padding:5px auto;
    margin: 3% auto 1%;
    transition: color 0.3s ease;
  }
  
  #register-form input[type="submit"]:hover,#login-form input[type="submit"]:hover{
    background-color: var(--backgroundColor);
  }
  
  .form-btn{
    width:50%;
    border-radius:10px;
    background-color:#F5761A;
    border:1px solid var(--backgroundColor);
    color:#fff;
    font-size:20px;
    padding:5px;
    margin: 4% auto;
    transition: color 0.3s ease;
  }
  
  .form-btn:hover{
      background-color: var(--backgroundColor);
  }
  
  
  .form-signup-link{
    text-decoration: none;
    font-size:1.3rem;
    color:#F5761A;
    text-shadow: 1px 1px 3px #F5761A;
    transition: color 0.3s ease;
  }
  
  .form-signup-link:hover{
    color: var(--backgroundColor);
  }
  
  

/* Cart Page  */

#cart-page{  
  
}

.container-style{
 width:95%;
 max-width:1300px;
 padding:13px 3px;
 margin:  20% auto; 
 /* background-color: #fff;   */
}

.cart-items-container{ 
 border-radius:5px;
 padding:5px;
 margin-bottom:5%;
}

.cart-items{
 background-color: #fff;
 display: flex;
 flex-direction:  row;
 align-items: center;
 padding:15px;
 margin: 3% auto;
 border-radius: 10px;  
 /* box-shadow: 1px 2px 15px 2px rgba(0,0,0,0.5); */

}

.cart-img{
 border:1px solid rgba(0,0,0,0.3);
 margin-right:5%;
 border-radius:10px;
 text-align: center;
 width:35%;
}

.cart-img img{
 width:100%;
}

.cart-item-info{
 margin-left:3%;
}

.cart-item-info .itemName{
  font-size:1.5rem;
  color:orangered;
}

.cart-item-info .itemQty{
 font-size:1rem;
 color:chocolate;
}

.cart-item-info .itemPrice{
 font-size:1.2rem;
 color:chocolate;
}

/* .cart-price-container{
  background-color: #fff;
  margin:auto;
  padding:1%;
}

.cart-price-container table {
 width:100%;
 margin: auto;
 border-collapse: separate;
 border-spacing: 0 10px;
 margin-bottom:3%;

}

.cart-price-container table tr h2{
  font-size:1.7rem;
}

.cart-price-container table tr td{
  font-size:1.3rem;

} */

#cart-price-total{
 background-color: #fff;
 margin-top:5%;
 padding: 20px;  
 border-radius:5px;
 /* box-shadow: 1px 2px 10px 2px rgba(0,0,0,0.5); */
}

.cart-price-heading{
 color: #F5761A;
 margin:5px auto 20px;
 text-shadow: 1px 1px 1px #ff8c1a;
 
}

.cart-summary-items{
 display: flex;
 justify-content: space-between;
 margin: 10px 8px;

}

.totalPrice{
 border-top: 1px solid rgba(0,0,0,0.2);
 margin-top:-5px;
 padding-top:13px;
}

.cart-summary-btn{
  padding: 6px 8px;
  font-size:1.3rem;
  color:#fff;
  background-color: #ff8c1a;
  border:none;
  border-radius: 8px;
  width:100%;  
  
}

.btn-cart-edit{
  color:var(--whiteColor);
  padding: 6px 10px;
  border-radius:10px;
  text-decoration: none;
  font-size:1rem;
}

.cart-item-info .cart-button{
 /* width:50%; */
 padding:5px 8px;  
 background-color:rgba(255,0,0,0.8);
 background-color: red;
 color:#fff;
 border:1px solid red;
 border-radius:7px;
 font-size:1rem; 
}

.empty-cart-txt{ 
 font-size:2.3rem;
}


  
/*Product info Section*/

.product-info-container{
  width:90%;  
  height:70vh; 

}

.product-info-items{
  display: flex;
  flex-direction:  column;
  align-items: center; 
  margin: 10% auto;
  padding: 3%;
  width:85%; 
}

.Shop-title{
  font-size:2rem;  
  margin: 5% auto;
  text-shadow: 1px 1px 2px #F5761A;
}

.product-info-img{ 
  margin-right:7%; 
  border-radius:20px;
  text-align: center;
  width:60%;  
}

.product-info-img img{
  width:80%;
}


.product-info{ 
  margin-left:auto;
  margin-top: 3%;
  text-align:center;
}

.product-info .itemName{
   font-size:2.5rem; 
   margin-bottom: 12px;
}

.product-info .itemQty{
  color:#ff8c1a;
}

/* .product-info .itemPrice{
   font-size:1.3rem;
   color:red;
} */

.product-info .qtySection button{
   width:35px;
   font-size:1.5rem;
   background-color: #ff8c1a;
   color:#fff;
   border:none;
   border-radius: 50%;
}

.product-info .qtyInput{
    width:25%;
    padding:3px;
    color:#F5761A;
    margin: 10px 13px;    
    text-align: center;     
    border:1px solid #F5761A;
    border-radius:5px;
    font-size:1.3rem; 
    outline: none;  
}

.product-info .itemPrice{
  font-size: 2.1rem;
  color:#F5761A;
  margin: 12px auto;

}

.product-info-cart-btn{
  width:100%;  
  padding:10px 8px;
  font-size:1.5rem;
  margin: 10px auto;
  background-color: #ff8c1a;
}

.product-info-cart-btn i {
  font-size: 1.7rem;
}



  
  
  }
  